import { Component, OnInit, Input } from '@angular/core';
import { Section } from './section';

@Component({
    selector: 'tsm-section-performance-grid',
    templateUrl: './section-performance-grid.component.html',
    styleUrls: ["./section-performance-grid.component.sass"]
})
export class SectionPerformanceComponent implements OnInit {
    @Input() sections: Section[] = [];
    @Input() subSections: any;

    sectionTitlesMap = {
        gettingStarted: "Getting Started",
        Environment: "Environment",
        "Human Social": "Human Rights & Social Impact",
        Supplier: "Supplier Inclusion & Diversity",
        esMgtConfig: "Ethics & Sustainability Management"
    };

    constructor() { }

    ngOnInit() {
        this.sections.forEach(section => {
            const sectionKey = this.getSectionKeyByName(section.name);
            if (sectionKey) {
                section.subsections = this.subSections[sectionKey] || [];
            }
            section.isExpanded = false;
        });
    }

    getSectionKeyByName(sectionName: string): string {
        return Object.keys(this.sectionTitlesMap).find(key => this.sectionTitlesMap[key] === sectionName);
    }

    toggleSection(selectedSection: Section): void {
        this.sections.forEach(section => {
            section.isExpanded = (section === selectedSection) ? !section.isExpanded : false;
        });
    }
}
