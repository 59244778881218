export const environment = {
    env: "hotfix",
    production: false,
    site: "tsm",
    role: "buyer",
    auth: {
        redirectUri: "https://tsm.ciohotfix.accenture.com",
        susSite: "c99364e2-ea4d-466e-81b7-39eeb05aa37b",
        policies: {
            signUpSignIn: {
                name: "B2C_1A_SignIn_SustainabilityHub_OIDC",
                authority:
                    "https://b2cbipstg.b2clogin.com/b2cbipstg.onmicrosoft.com/B2C_1A_SignIn_SustainabilityHub_OIDC"
            },
            forgotPassword: {
                name: "b2c_1_reset",
                //R1 - Looks like this isn't currently used
                authority: "https://b2cbipstg.b2clogin.com/b2cbipstg.onmicrosoft.com/b2c_1_reset"
            },
            editProfile: {
                name: "b2c_1_edit_profile",
                //R1 - Looks like this isn't currently used
                authority: "https://b2cbipstg.b2clogin.com/b2cbipstg.onmicrosoft.com/b2c_1_edit_profile"
            },
            updatePassword: {
                name: "B2C_1A_ChangePassword_SustainabilityHub_OIDC",
                authority:
                    "https://b2cbipstg.b2clogin.com/b2cbipstg.onmicrosoft.com/B2C_1A_ChangePassword_SustainabilityHub_OIDC"
            },
            updatePhoneNumber: {
                name: "B2C_1A_EDITMFAPHONENUMBER_SUSHUB_OIDC",
                authority:
                    "https://b2cbipstg.b2clogin.com/b2cbipstg.onmicrosoft.com/B2C_1A_EDITMFAPHONENUMBER_SUSHUB_OIDC"
            }
        },
        authorityDomain: "b2cbipstg.b2clogin.com",
        apiConfig: {
            scopes: ["openid", "c99364e2-ea4d-466e-81b7-39eeb05aa37b", "offline_access"],
            //Update this with load balancer once the network call loop is fixed
            uri: "temp-value"
        },
        accentureTenantId: "f3211d0e-125b-42c3-86db-322b19a65a22",
    },
    languageSwitch: true,
    gateway: "",
    landing_url: "https://tsm.ciodev.accenture.com/#/",
    relationship_url: "https://tsm.ciodev.accenture.com/#/",
    landingdev_url: "https://tsm.ciodev.accenture.com/#/",
    relationshipdev_url: "https://tsm.ciodev.accenture.com/#/",
    landingtest_url: "https://tsm.ciotest.accenture.com/#/",
    relationshiptest_url: "https://tsm.ciotest.accenture.com/#/",
    landingperf_url: "https://tsm-perf.ciodev.accenture.com/#/",
    relationshipperf_url: "https://tsm-perf.ciodev.accenture.com/#/",
    landinghotfix_url: "https://tsm.ciohotfix.accenture.com/#/",
    relationshiphotfix_url: "https://tsm.ciohotfix.accenture.com/#/",
    landingstage_url: "https://tsm.ciostage.accenture.com/#/",
    relationshipstage_url:
        "https://tsm.ciostage.accenture.com/#/",
    SUSHUBEMAILServiceURL: "https://tsm.ciohotfix.accenture.com/sushub/email-service",
    tsmBackendServiceURL: "https://tsm.ciohotfix.accenture.com/tsm/backend-service",
    susHubB2CUserMgtServiceURL: "https://tsm.ciohotfix.accenture.com/sushub/b2c-user-mgt-service",
    susHubESGServiceURL: "https://tsm.ciohotfix.accenture.com/sushub/esg-service",
    susHubTaskServiceURL: "https://tsm.ciohotfix.accenture.com/sushub/task-service",
    b2cAuthService: "https://tsm.ciohotfix.accenture.com/tsm/b2c-auth-service",
    supplierHubRedirectBaseUrl: "https://supplierhub-buyer-hotfix.ciostage.accenture.com/#/tsmcross",
    supplierHubRedirectBaseSupplierUrl: "https://supplierhub-hotfix.ciostage.accenture.com/#/tsmcross/landing/tsmsupplier",
    susHubContact: "110556-b02-tsm-logs-hotfix",
    susAss: "Sustainability_Assessment_Report.csv",
    processAss: "Process_Report.csv",
    companyLogoBucketName: "110556-b02-esg-questionnaire-file-uploads-hotfix",
    tsmReportServiceURL: "https://tsm.ciohotfix.accenture.com/tsm/report-service",
    supplierHubOnboardingViewURL: "https://supplierhub-buyer-hotfix.ciostage.accenture.com/#/buyer",
    supplierHubBaseUrl: "https://supplierhub-hotfix.ciostage.accenture.com",
    powerBi: {
        clientId: "fddb24d0-dda5-4574-9341-a0f99c6c0963",
        authority: "https://login.microsoftonline.com/accenture.onmicrosoft.com",
        scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
        redirectUri: "https://tsm.ciohotfix.accenture.com/"
    }
};
