<div id="perfGrid" class="perf-content">
    <div *ngFor="let sec of sections">
        <div class="row perf-row m-0">
            <div class="col-10">
                {{ sec.name }}
            </div>
            <div class="col-2 d-flex justify-content-between align-items-center">
                <span class="score">{{ sec.score }}%</span>
                <div class="chevron ml-2" [ngClass]="{'rotate': sec.isExpanded}" (click)="toggleSection(sec)">
                    <img src="assets/images/baseline-expand_more.svg">
                </div>
            </div>
        </div>

        <div *ngIf="sec.isExpanded && sec.subsections && sec.subsections.length > 0">
            <ng-container *ngFor="let subsec of sec.subsections">
                <div *ngIf="subsec.name !== 'General'" class="row perf-row m-0">
                    <div class="col-10">{{ subsec.name }}</div>
                    <div class="col-2 d-flex justify-content-between align-items-center">
                        <div class="score">{{ subsec.score }}%</div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>