<div>
    <div class="row row-cols-auto">
        <!-- Due diligence Section -->
        <div class="scoring-container" style="width: 66%">
            <div class="d-flex section-header">
                <div class="me-auto align-self-center section-title cursor-pointer">{{ translations.dueDiligenceSummary }}</div>
                <div class="align-self-center report">
                    <div [ngClass]="(criticalAnswers.length > 0) ? 'performance-status-improvement' : 'performance-status-compliant'">
                            <img src="assets/images/score-improvement.svg" alt="score-improvement" style="max-height: 20px" *ngIf="criticalAnswers.length" />
                            <img src="assets/images/score-compliant.svg" alt="score-compliant" style="max-height: 20px" *ngIf="!criticalAnswers.length" />
                            <span class="assessment-item">{{ performanceStatus }}</span>
                    </div>
                </div>
            </div>

            <!-- questions grid -->
            <div class="questions-container" *ngIf="criticalAnswers.length; else noCriticalAnswersTemplate">
                <div class="row questions-header-row m-0" [ngClass]="criticalAnswers.length >5? 'pe-20':''">
                    <div class="col" style="min-width: 65%;">{{translations.QUESTION}}</div>
                    <div class="col" style="min-width: 15%;">{{translations.RESPONSE}}</div>
                    <div class="col" style="min-width: 20%;">{{translations.COMMENT}}</div>
                </div>
                <div sushubGridScroll [rows]="5">
                    <div class="row questions-row m-0" *ngFor="let criticalGap of criticalAnswers">
                        <div class="col" style="min-width: 65%;">{{criticalGap.question}}</div>
                        <div class="col" style="min-width: 15%;">{{criticalGap.response}}</div>
                        <div class="col  pre-wrap" style="min-width: 20%;">{{criticalGap.comment}}</div>
                    </div>
                </div>
            </div>

        </div>
        <div style="width: 1%" class="p-0"></div>
        <!-- Score Section -->
        <div class="scoring-container" style="width: 32%">
            <div [ngClass]="overallScoreVisible ? 'score-section-header' : 'section-header'">
                <div class="section-title">{{ translations.score }}</div>

                <div *ngIf="overallScoreVisible" class="d-flex" style="height: 26px;">
                    <div class="me-auto align-self-center section-sub-title">{{ translations.overallScore }}</div>
                    <div class="align-self-center overall-score-value">{{ reviewData?.score?.overallScore }}</div>
                </div>
            </div>

            <div class="row m-0">
                <div class="col-12 p-0">
                    <score-chart [scoreLevel]="reviewData?.score?.scoreLevel" style="float: right;padding-top: 24px;width: 100%;overflow-x: scroll;"></score-chart>
                </div>
                <div class="col-12 pt-24 pb-24 ps-0 pe-0">
                    <tsm-section-performance-grid [sections]="sections" [subSections]="subSections"></tsm-section-performance-grid>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #noCriticalAnswersTemplate>
    <div class="no-criticalgaps-container">
        <img src="assets/images/NoCriticalGaps.svg" alt="noCriticalGaps"/>
        <div class="no-criticalgaps-msg-text">
            {{ translations.noCriticalGapsMsg }}
        </div>
    </div>
</ng-template>